<template>
  <div class="backdrop-container">
  <vue-element-loading :active="loading" is-full-screen>
        <img src="@/assets/gif/Motion-Logo-.gif"  style="width: 50%; max-width: 250px;">
    </vue-element-loading>
    <div class="bd-content" v-if="contentBackdrop === '21factory'" :class="[factory ? 'factory' : '']">
     <!-- <img src="@/assets/case/Group 315-min.png" alt="21 factory" id="duasatu-img"> -->

     <img @load="imgLoad" src="@/assets/case/Path 336-min.png" alt="arrow-back" id="arrow-back" @click.prevent="goBack">
      <div class="bd-text">
      <h1>21 Factory</h1>
      <p>{{lang === 'en' ? 'Our premium gelato made with authenctic italian recipe and natural ingredients made your day' : 'Premium gelato yang dibuat dengan resep Italia nan otentik lengkap dengan bahan berkualitas spesial untuk harimu'}}</p>

      </div>
    </div>

    <div class="bd-content" v-if="contentBackdrop === 'Devon'" :class="[devon ? 'devon' : '']">
     <img @load="imgLoad" src="@/assets/case/Path 336-min.png" alt="arrow-back" id="arrow-back" @click.prevent="goBack">

      <div class="bd-text">
      <h1>Devon Cafe</h1>
      <p>{{lang === 'en' ? 'The minimalist and stylishly casual interior dominated with earthy tones effortlessly livens the mood for brunch with stunning food' : 'Interior minimalis dan bergaya kasual yang mampu menghidupkan mood kamu untuk menikmati sajian brunch dengan nikmat'}}</p>
      </div>
    </div>

    <div class="bd-content" v-if="contentBackdrop === 'Walking'" :class="[walking ? 'walking' : '']">
     <img @load="imgLoad" src="@/assets/case/Path 336-min.png" alt="arrow-back" id="arrow-back" @click.prevent="goBack">

      <div class="bd-text">
      <h1>Walking Drums</h1>
<p>{{lang === 'en' ? 'Walking Drums pulls crowds with its laid-back atmosphere, making it an ideal place to wind down on a lazy day' : 'Menarik perhatian dengan suasana yang nyaman dan menjadikannya tempat ideal untuk bersantai'}}</p>
      </div>
    </div>

    <div class="bd-content" v-if="contentBackdrop === 'Kintaro'" :class="[kintaro ? 'kintaro' : '']">
     <img @load="imgLoad" src="@/assets/case/Path 336-min.png" alt="arrow-back" id="arrow-back" @click.prevent="goBack">

      <div class="bd-text">
      <h1>Kintaro Sushi</h1>
      <p>{{lang === 'en' ? 'Japanese joint serving creative sushi rolls, sashimi & noodle dishes in contemporary digs' : 'Restoran Jepang yang menyajikan sushi gulung, sashimi & berbagai varian mie secara kontemporer'}}</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['contentBackdrop', 'lang'],
  data: function () {
    return {
      factory: false,
      devon: false,
      walking: false,
      kintaro: false,
      loading: true
    }
  },
  watch: {
    lang () {
      console.log('belibeli')
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  },
  methods: {
    goBack () {
      this.$router.push('/case-study')
    },
    imgLoad () {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (window.location.pathname === '/case-study/21-factory') {
      this.factory = true
    }

    if (window.location.pathname === '/case-study/devon') {
      this.devon = true
    }

    if (window.location.pathname === '/case-study/walking-drums') {
      this.walking = true
    }

    if (window.location.pathname === '/case-study/kintaro-sushi') {
      this.kintaro = true
    }
  }
}
</script>

<style scoped>
.backdrop-container {
  width: 100vw;
  height: calc(100vh - 25vh);
  min-height: 455px;
}

.bd-content {
  width: 100%;
  height: 100%;
  position: relative;

}

.factory {
    background: url("~@/assets/case/Group 315-min.png") no-repeat center center fixed;

background-size: cover;
  overflow: hidden;
}

.devon {
    background: url("~@/assets/case/Group 313-min.png") no-repeat center center fixed;

background-size: cover;
  overflow: hidden;
}

.walking {
    background: url("~@/assets/case/Group 316-min.png") no-repeat center center fixed;

background-size: cover;
  overflow: hidden;
}

.kintaro {
    background: url("~@/assets/case/Group 312-min.png") no-repeat center center fixed;

background-size: cover;
  overflow: hidden;
}

#arrow-back {
  width: 1rem;
  height: auto;
  position: absolute;
  top: 3rem;
  left: 3rem;
  z-index: 99;
  transition: 0.3s;
}

#arrow-back:hover {
  cursor: pointer !important;
  transform: scale(1.2)
}

.bd-content h1, .bd-content p {
  z-index: 2;
  color: white;
  margin: 10px 0;
}

.bd-text {
  z-index: 9;
  position: relative;
  text-align: center;
  padding: 0 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bd-text h1 {
  font-family: 'Arial', sans-serif;
  font-size: 3rem;
}

/* #duasatu-img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
} */

@media (max-width: 768px) {
  .bd-text {
    padding: 0 5%;
  }

  #arrow-back {
    left: 2rem;
  }
}
</style>
