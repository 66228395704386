<template>
  <div class="case-content">
    <div id="logoCase-container">
      <img src="@/assets/case/Group 320-min.png" alt="logo" v-if="contentBackdrop === 'Kintaro'" />
      <img src="@/assets/case/Devon (2)-min.png" alt="logo" v-if="contentBackdrop === 'Devon'" />
      <img src="@/assets/case/w drums-min.png" alt="logo" v-if="contentBackdrop === 'Walking'" />
      <img src="@/assets/case/Group 321-min.png" alt="logo" v-if="contentBackdrop === '21factory'" />
    </div>

    <div class="title-case">
      <div v-if="contentBackdrop === 'Kintaro'">
        <h1>{{lang=== 'en'? 'Service' : 'Servis'}}</h1>

        <p v-if="windowWidth > 576">
          {{lang === 'en' ? 'Data Analytics Customer Support' : 'Data Analisis Pengunjung'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> Email Blast
        </p>
        <div id="list-camp" v-else>
          <p>{{lang === 'en' ? 'Data Analytics Customer Support' : 'Data Analisis Pengunjung'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>Email Blast</p>
        </div>
      </div>

      <div v-if="contentBackdrop === 'Devon'">
        <h1>{{lang === 'en' ? 'Service' : 'Servis'}}</h1>
        <p v-if="windowWidth > 576">
          {{lang === 'en' ? 'Data Analytics Customer Support' : 'Report Data Analisis Pengunjung'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> Email Blast
        </p>

        <div id="list-camp" v-else>
          <p>{{lang === 'en' ? 'Data Analytics Customer Support' : 'Report Data Analisis Pengunjung'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>Email Blast</p>
        </div>
      </div>

      <div v-if="contentBackdrop === '21factory'">
        <h1>{{lang === 'en' ? 'Service' : 'Servis'}}</h1>
        <p v-if="windowWidth > 576">
          {{lang === 'en' ? 'Data Analytics Customer Support' : 'Report Data Analisis Pengunjung'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Wifi Gaming Page' : 'Game melalui WiFi'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> Email Blast
        </p>
        <div id="list-camp" v-else>
          <p>{{lang === 'en' ? 'Data Analytics Customer Support' : 'Report Data Analisis Pengunjung'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Wifi Gaming Page' : 'Game melalui WiFi'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>Email Blast</p>
        </div>
      </div>

      <div v-if="contentBackdrop === 'Walking'">
        <h1>{{lang === 'en' ? 'Service' : 'Servis'}}</h1>
        <p v-if="windowWidth > 576">
          {{lang === 'en' ? 'Data Analytics Customer Report' : 'Report Data Analisis Pengunjung'}}
          <span>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> {{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}
          <span>
            <img src="@/assets/case/Ellipse 43-min.png" alt />
          </span> Email Blast
        </p>

        <div id="list-camp" v-else>
          <p>{{lang === 'en' ? 'Data Analytics Customer Report' : 'Report Data Analisis Pengunjung'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Digital Survey' : 'Survei Digital'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>{{lang === 'en' ? 'Wifi Promotional Page' : 'Promosi melalui WiFi'}}</p>
          <img src="@/assets/case/Ellipse 43-min.png" alt />
          <p>Email Blast</p>
        </div>
      </div>
    </div>

    <div class="feedback-case">
      <img
        src="@/assets/case/kintaro1-min.png"
        alt
        id="kintaro-phone"
        v-if="contentBackdrop === 'Kintaro'"
      />
      <img
        src="@/assets/case/devon-min.png"
        alt
        id="other-phone"
        v-if="contentBackdrop === 'Devon'"
      />

      <img
        src="@/assets/case/21 fact-min.png"
        alt
        id="other-phone"
        v-if="contentBackdrop === '21factory'"
      />

      <img
        src="@/assets/case/walking drms-min.png"
        alt
        id="other-phone"
        v-if="contentBackdrop === 'Walking'"
      />

      <div class="feedback-text">
        <div v-if="contentBackdrop === 'Kintaro'">
          <h1>Feedback</h1>
          <p>{{lang === 'en' ? 'Awereness for daily promotion page' : 'Meningkatkan awareness lewat promosi harian'}}</p>
          <p>{{lang === 'en' ? 'Automatically deliver email notification for customer' : 'Secara otomatis mengirimkan email untuk pelanggan'}}</p>
        </div>

        <div v-if="contentBackdrop === 'Devon'">
          <h1>Feedback</h1>
          <p>{{lang === 'en' ? 'Awareness for daily promotion page' : 'Meningkatkan awareness lewat promosi harian'}}</p>
        </div>

        <div v-if="contentBackdrop === '21factory'">
          <h1>Feedback</h1>
          <p>{{lang == 'en' ? 'Awareness for daily promotion page' : 'Meningkatkan awareness lewat promosi harian'}}</p>
        </div>

        <div v-if="contentBackdrop === 'Walking'">
          <h1>Feedback</h1>
          <p>{{lang === 'en' ? 'Awereness for weekly promotion page' : 'Meningkatkan awareness lewat promosi mingguan'}}</p>
        </div>
      </div>

      <div class="feedback-survey" v-if="windowWidth > 576">
        <img src="@/assets/case/Rectangle 216-min.png" alt="blue rectangle" />
        <div class="survey" v-if="contentBackdrop === 'Kintaro'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>9086</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Total Database yang terkumpul'}}</h3>
            <h2>9130</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>86%</h2>
          </div>
        </div>

        <div class="survey" v-if="contentBackdrop === 'Devon'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Total Database yang terkumpul'}}</h3>
            <h2>2163</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>70%</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>1883</h2>
          </div>
        </div>

        <div class="survey" v-if="contentBackdrop === '21factory'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>1517</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>66%</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>804</h2>
          </div>
        </div>

        <div class="survey" v-if="contentBackdrop === 'Walking'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>13603</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>50,7%</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>1883</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="feedback-survey" v-if="windowWidth < 575.98">
        <div class="survey" v-if="contentBackdrop === 'Kintaro'">
          <div class="survey-1">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>9086</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>9130</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>86%</h2>
          </div>
        </div>

        <div class="survey" v-if="contentBackdrop === 'Devon'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>2163</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>70%</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>1883</h2>
          </div>
        </div>

        <div class="survey" v-if="contentBackdrop === '21factory'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>1517</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>66%</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>804</h2>
          </div>
        </div>

        <div class="survey" v-if="contentBackdrop === 'Walking'">
          <div class="survey-1">
            <h3>{{lang === 'en' ? 'Total Number Database Collected' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>13603</h2>
          </div>

          <div class="survey-2">
            <h3>{{lang === 'en' ? 'Conversion to Returning Visitor Rate' : 'Konversi Pengunjung yang Kembali'}}</h3>
            <h2>50,7%</h2>
          </div>

          <div class="survey-3">
            <h3>{{lang === 'en'  ? 'Total Respondent Survey Customer' : 'Jumlah Responden Survei terkumpul'}}</h3>
            <h2>1883</h2>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ['contentBackdrop', 'lang'],
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },
  created () {
    this.windowWidth = window.innerWidth
  }
}
</script>

<style scoped>
#list-camp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

#list-camp img {
  width: .9rem;
  margin: .5rem 0;
}

#list-camp p {
  font-size: 1rem;
}

.case-content {
  width: 100%;
  min-height: auto;
  height: auto;
  position: relative;
}

#logoCase-container {
  width: 100%;
  min-height: 3rem;
  height: auto;
  position: absolute;
  top: -6rem;
  text-align: center;
}

#logoCase-container img {
  width: 12rem;
  height: auto;
}

.title-case {
  padding-top: 10%;
  margin: 0 auto 7rem auto;
}

.title-case h1,
.feedback-case h1 {
  margin-bottom: 3rem;
  text-align: center;
}

.title-case p,
.feedback-case p {
  font-family: "Arial", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}

.feedback-case h1,
.feedback-case p {
  text-align: left;
}

.feedback-case h1 {
  margin-bottom: 0.4rem;
}

.feedback-case p {
  line-height: 1.8;
  width: 52%;
  margin-bottom: 10px;
}

.title-case img {
  width: 1.2%;
  height: auto;
}

.title-case span {
  margin: 0 20px;
}

.feedback-case {
  margin: 0 auto;
  padding-left: 8rem;
  padding-right: 8rem;
  position: relative;
  min-height: 440px;
  height: auto;
  margin-bottom: 7rem;
}

.feedback-case #kintaro-phone, .feedback-case #other-phone {
  position: absolute;
  width: 20%;
  height: auto;
  right: 8rem;
  top: 1rem;
  z-index: 3;
}

.feedback-survey {
  margin-top: 3rem;
  position: relative;
  height: auto;
}

.feedback-survey .survey {
  width: 41rem;
  height: 100%;
  color: white;
  display: flex;
  padding: 10px;
}

.survey-1,
.survey-2,
.survey-3 {
  z-index: 2;
  position: relative;
  margin: 2rem 0;
  width: 50%;
  padding: 0;
}

.survey-1 h3,
.survey-2 h3,
.survey-3 h3 {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 25px;
}

.survey-1 h2,
.survey-2 h2,
.survey-3 h2 {
  font-family: "Arial", sans-serif;
  text-align: center;
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
}

.survey-1,
.survey-2 {
  border-right: 1px solid white;
}

.feedback-survey img {
  width: 50rem;
  position: absolute;
  top: 0;
  left: 0;
}

/* @media screen and (min-width: 576px) and (max-width: 767px) {
  .title-case,
  .feedback-case {
    width: 540px;
    padding-right: 0;
    padding-left: 0;
  }

  .title-case p {
    line-height: 1.7;
  }

  .feedback-text h1,
  .feedback-text p {
    text-align: center;
  }

  .feedback-text p {
    width: 70%;
    margin: 0 auto 385px auto;
  }

  .feedback-survey img {
    width: 100%;
  }

  .feedback-survey .survey {
    width: 100%;
  }

  .feedback-survey .survey h3 {
    margin-bottom: 1rem;
  }

  .feedback-case #kintaro-phone {
    width: 200px;
    top: 8rem;
    left: 37%;
    z-index: 60;
  }
} */

@media (min-width: 768px) and (max-width: 991px) {
  .title-case,
  .feedback-case {
    width: 720px;
    padding-right: 0;
    padding-left: 0;
  }

  .title-case p {
    line-height: 1.7;
  }

  .feedback-text h1,
  .feedback-text p {
    text-align: center;
  }

  .feedback-survey {
    margin-top: 25rem;
  }

  .feedback-text p {
    width: 70%;
    margin: 0 auto 10px auto;
  }

  .feedback-survey img {
    width: 100%;
  }

  .feedback-survey .survey {
    width: 100%;
  }

  .feedback-survey .survey h3 {
    margin-bottom: 1rem;
    height: 2rem;
  }

  .feedback-case #kintaro-phone {
    width: 200px;
    top: 9rem;
    left: 37%;
    z-index: 60;
  }

  .feedback-case #other-phone {
    width: 200px;
    top: 7.5rem;
    left: 37%;
    z-index: 60;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .title-case,
  .feedback-case {
    width: 960px;
  }

  .feedback-case {
    padding: 0 3rem;
  }

  .feedback-case #kintaro-phone, .feedback-case #other-phone {
    right: 3rem;
  }

  .feedback-survey img {
    width: 45rem;
  }

  .survey-1 h3,
  .survey-2 h3,
  .survey-3 h3 {
    margin-bottom: 1rem;
  }

  .feedback-survey .survey {
    width: 43rem;
  }
}

@media (min-width: 1200px) {
  .title-case,
  .feedback-case {
    width: 1140px;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .title-case {
    padding-top: 15%;
    margin: 0 auto 5rem auto;
  }

  .title-case,
  .feedback-case {
    padding-right: 0;
    padding-left: 0;
  }

  .title-case p {
    line-height: 1.7;
    padding: 0 5%;
  }

  .feedback-text h1,
  .feedback-text p {
    text-align: center;
  }

  .feedback-survey {
    width: 90%;
    margin: 20rem auto 0  auto;
  }

  .feedback-text p {
    width: 70%;
    margin: 0 auto 10px auto;
  }

  .feedback-survey img {
    width: 100%;
  }

  .feedback-survey .survey {
    width: 100%;
  }

  .feedback-survey .survey h3 {
    margin-bottom: 1rem;
    height: 2rem;
  }

  .feedback-case #kintaro-phone {
    width: 160px;
    top: 8.5rem;
    left: 38%;
    z-index: 60;
  }

  .feedback-case #other-phone {
    width: 160px;
    top: 8rem;
    left: 38%;
    z-index: 60;
  }

  .survey-1,
  .survey-2,
  .survey-3 {
    z-index: 2;
    position: relative;
    margin: 1rem 0;
    width: 50%;
  }

  .survey-1 h3,
  .survey-2 h3,
  .survey-3 h3 {
    margin-top: .5rem;
    padding: 0 5px;
  }
}

@media (max-width: 575.98px) {
  .title-case {
    padding: 5rem 5% 0 5%;
    margin: 0 auto 3rem auto;
  }

  .title-case p {
    line-height: 1.8;
  }

  .feedback-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .feedback-case {
    padding: 0 5% 0 5%;
    min-height: 500px;
    margin-bottom: 4rem;
  }

  .feedback-text h1 {
    text-align: center;
  }

  .feedback-text p {
    width: 100%;
    margin: .5rem auto;
    text-align: center;
    font-size: 1rem
  }
  .feedback-survey {
    height: 500px;
    background: rgb(0,67,171);
background: linear-gradient(180deg, rgba(0,67,171,0.9900911048012955) 0%, rgba(1,205,232,1) 67%);
  }

  .feedback-survey .survey {
    width: 100%;
    height: 100%;
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 0;
    justify-content: space-evenly;
  }

  .survey-1, .survey-2, .survey-3 {
    margin: unset;
    padding: 1.5rem 5%;
    width: 100%;
    border-right: unset;
    position: relative
  }

  .survey-1::before, .survey-2::before {
    content: ' ';
    width: 35%;
    height: 85%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #fff;
  }

  .survey-1 h3, .survey-2 h3, .survey-3 h3 {
    margin-bottom: 1rem;
  }

  .feedback-case #kintaro-phone, .feedback-case #other-phone {
    position: absolute;
    width: 180px;
    height: auto;
    /* right: 25%; */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: unset;
    bottom: 0;
  }
}
</style>
